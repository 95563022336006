import { TariffPlan, TariffPrice } from '@roolz/types/api/billing'
import { useMemo } from 'react'
import { useSdkContext } from '@roolz/sdk/SdkContext'

export const usePlanPricing = (plan: TariffPlan): TariffPrice => {
  const { pricingCountryAlpha2 } = useSdkContext()

  return useMemo((): TariffPrice => {
    const defaultPrice = {
      ...plan.default_price,
      currency: 'USD',
      country_code: '',
    }

    let countryTariff

    if(pricingCountryAlpha2) {
      countryTariff = plan.pricing.find(
        ({ country_code }) => country_code.toLowerCase() === pricingCountryAlpha2.toLowerCase(),
      )
    }

    return countryTariff || defaultPrice
  }, [plan.default_price, plan.pricing, pricingCountryAlpha2])
}
