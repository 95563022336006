import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { observer } from 'mobx-react-lite'
import {
  MonthlyPriceForYearPanel,
} from '@roolz/sdk/components/pricing/ui/MonthlyPriceForYearPanel/MonthlyPriceForYearPanel'
import { usePricingPeriodState } from '@roolz/sdk/components/pricing/hooks/usePricingPeriodState'
import { UCFirst } from '@roolz/sdk/utils/formatting'
import { useBillingPlanView } from '@roolz/sdk/components/pricing/hooks/useBillingPlanView'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from '@roolz/sdk/components/pricing/blocks/PlansScope/desktop/TablePlanHead/TablePlanHead.module.scss'

interface Props {
  tariff: TariffPlan
}

export const TablePlanHead = observer(({
  tariff,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  const [paymentPeriod] = usePricingPeriodState()

  const {
    price,
    priceType,
    currencySymbol,
  } = useBillingPlanView(tariff, {
    paymentPeriod,
  })

  const showMonthlyPriceTipLabel = ![TariffPlanType.Free, TariffPlanType.Enterprise].includes(tariff.type)

  return (
    <th className={styles.root}>
      <div className={styles.price}>
        {tariff.type !== TariffPlanType.Enterprise ? (
          <>
            <span className={styles.price__quantity}>
              {currencySymbol}
              {Number(price) === 0 ? 0 : price}
            </span>

            <span className={styles.price__type}>
              {' / '}
              {/* <br/> */}
              {t(`plans.price_types.${priceType}`)}
            </span>
          </>
        ) : (
          <span className={styles.price__quantity}>
            {UCFirst(t('plans.price_types.individual'))}
          </span>
        )}

        {paymentPeriod === PaymentPeriods.Annual && (
          <MonthlyPriceForYearPanel
            className={styles.monthlyPricePanel}
            visible={showMonthlyPriceTipLabel}
          />
        )}
      </div>
    </th>
  )
})
