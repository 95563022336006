import DangerousFill from '@material-symbols/svg-400/rounded/dangerous-fill.svg'
import Info from '@material-symbols/svg-400/rounded/info.svg'
import Warning from '@material-symbols/svg-400/rounded/warning.svg'
import Dangerous from '@roolz/icons/lottie/Dangerous'
import { Warning1 } from '@roolz/icons/lottie/Warning1'
import { Warning2 } from '@roolz/icons/lottie/Warning2'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import { Loadable } from '@roolz/sdk/components'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { Billing, BillingExpireState } from '@roolz/types/api/billing'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { systemStore } from '@/store/system/system.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { billingService } from '@/store/billing/billing.service'
import styles from './PremiumPlanPaymentCountdown.module.scss'

interface Props {
  billing: Billing
  className?: string
}

export const PremiumPlanPaymentCountdown = observer(({
  billing,
  className,
}: Props) => {
  const { t } = useTranslation('ui', { keyPrefix: 'premium_plan_payment_countdown' })

  const {
    billing_expire_state,
    billing_next_state_time,
    tariff_plan,
    billing_period,
    prev_billing_period,
    prev_tariff_type,
  } = billing

  const [loading, setLoading] = useState(false)

  async function handlePay() {
    const activeSpace = profilesStore.activeCompanyId as string

    try {
      setLoading(true)

      if(billing_expire_state === BillingExpireState.Gross) {
        await billingService.changePlan(activeSpace, tariff_plan.type, billing_period)
      } else if(
        [
          BillingExpireState.Dying,
          BillingExpireState.Dead,
        ].includes(billing_expire_state as BillingExpireState)
      ) {
        if(!prev_tariff_type || !prev_billing_period) {
          console.error('Prev billing type or period is empty', prev_tariff_type, prev_billing_period)
          setLoading(false)

          return
        }

        await billingService.changePlan(activeSpace, prev_tariff_type, prev_billing_period)
      }
    } catch(e) {
      setLoading(false)
    }
  }

  const expirationTime = useMemo(() => {
    const date = billing_next_state_time

    const daysTrans = t('time.days')
    const hoursTrans = t('time.hours')
    const minutesTrans = t('time.minutes')

    const diff = dayjs(Math.abs(date * 1000))
      .diff(systemStore.serverTime10secUpdate)

    if(diff <= 0) {
      return `0 ${daysTrans}`
    }

    const duration = dayjs.duration(diff)

    if(duration.days() > 0) {
      return duration.format(`D${daysTrans} H${hoursTrans}`)
    }
    if(duration.minutes() >= 1) {
      return duration.format(`H${hoursTrans} m${minutesTrans}`)
    }

    return `<1${minutesTrans}`
  }, [systemStore.serverTime10secUpdate])

  return (
    <div
      className={cn(styles.root, {
        [styles.rootWarning]: billing_expire_state === BillingExpireState.Gross,
        [styles.rootError]: [BillingExpireState.Dying, BillingExpireState.Dead].includes(billing_expire_state as BillingExpireState),
      }, className)}
    >
      <MaterialSymbolIcon
        className={styles.icon}
        size={20}
        icon={billing_expire_state === BillingExpireState.Gross
          ? <Warning1 style={{ width: 20 }}/>
          : <Dangerous style={{ width: 16 }}/>}
      />

      <div className={styles.content}>
        <div className={styles.title}>
          {t('title')}
        </div>
        <div className={styles.description}>
          {billing_expire_state === BillingExpireState.Gross ? (
            <>
              <MaterialSymbolIcon
                className={styles.description__prepend}
                size={14}
                icon={<Info/>}
              />
              {t('countdown')}
              <b style={{ marginLeft: 2 }}>
                {expirationTime}
              </b>
            </>
          ) : (
            t('functions_limited')
          )}
        </div>
      </div>

      <Loadable loading={loading}>
        <Button
          className={styles.button}
          onClick={handlePay}
        >
          {t('pay')}
        </Button>
      </Loadable>
    </div>
  )
})
