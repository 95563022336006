import { toastWarning } from '@roolz/sdk/components/snackbars'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { useLayoutStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useModalControl } from '@roolz/sdk/hooks/useModalControl'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { Company } from '@roolz/types/api/companies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { createContext, ReactNode, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { profilesStore } from '@/store/profiles/profiles.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { billingStore } from '@/store/billing/billing.store'
import { billingService } from '@/store/billing/billing.service'
import { useConfirmation } from '@/confirmation/ConfirmationContext'
import { MAX_COMPANIES_COUNT } from '@/config/const'
import { LoadingOverlay } from '@/components/ui/LoadingOverlay/LoadingOverlay'
import { CreateCompanyShort } from '@/components/modals/companies/CreateCompanyShort/CreateCompanyShort'
import { SelectPremiumPlan } from '@/components/modals/billing/SelectPremiumPlan/SelectPremiumPlan'

// Probably context is not the best choice. Rewrite later
export type ContextType = {
  changePlan: (period?: PaymentPeriods) => void
}
export const ChangeTariffProcessContext = createContext<ContextType>({
  changePlan: () => {},
})

// type Children = (props: {
//   changePlan: (period?: PaymentPeriods) => void
// }) => ReactNode

type Props = {
  open?: boolean
  children?: ReactNode
}

export const ChangeTariffProcess = observer(({
  open,
  children,
}: Props) => {
  const { t } = useTranslation('company/pricing')
  const confirmation = useConfirmation()
  const selectPlan = useModalControl(open)
  const [paymentInNewTab, setPaymentInNewTab] = useState(false)

  const createCompany = useModalControl()

  const [loading, setLoading] = useState(false)
  const loadingRef = useLayoutStateRef(loading)

  const [defaultPeriod, setDefaultPeriod] = useState<PaymentPeriods | undefined>()

  const confirmPlanChange = (type: TariffPlanType, period: PaymentPeriods) => new Promise(resolve => {
    const onConfirm = () => {
      confirmation.close()

      return resolve(true)
    }

    const onReject = () => {
      confirmation.close()

      return resolve(false)
    }

    confirmation.confirm({
      title: t('change_plan_modal.title'),
      content: (
        <Trans
          i18nKey='company/pricing:change_plan_modal.description'
          values={{
            planName: t(`plans.${type}.name`),
            planPeriod: t(`payment.payment_period.${period}`),
          }}
        />
      ),
      actions: (
        <>
          <Button variant='text' onClick={onReject}>
            {t('change_plan_modal.cancel')}
          </Button>
          <Button
            variant='text'
            onClick={onConfirm}
          >
            {t('change_plan_modal.confirm')}
          </Button>
        </>
      ),
      onConfirm,
    })
  })

  const [onCompanyCreated, setOnCompanyCreated] = useState<((company: Company) => void) | undefined>()

  const handlePlanSelected = async (plan: TariffPlan, period: PaymentPeriods) => {
    if(plan.type === TariffPlanType.Enterprise) {
      window.open('mailto:hey@roolz.net', '_blank')

      return
    }

    const companyId = profilesStore.activeCompanyId
    const myCompanies = myCompaniesStore.companies

    if(!companyId) {
      // TODO think what to do if I'm not an admin of company
      if(myCompanies.length < MAX_COMPANIES_COUNT) {
        setOnCompanyCreated(() => (company: Company) => {
          finishPlanChange(company.id, plan.type, period)
        })
        selectPlan.close()
        createCompany.show()
      }

      return
    }

    // if(currentPlan?.type === TariffPlanType.Free || await confirmPlanChange(plan, period)) {
    return finishPlanChange(companyId, plan.type, period)
    // }
  }

  const finishPlanChange = useCallback(async (companyId: Company['id'], type: TariffPlanType, period: PaymentPeriods) => {
    if(loadingRef.current) return

    const currentPlan = billingStore.billing?.tariff_plan
    if(currentPlan && currentPlan?.type !== TariffPlanType.Free && !(await confirmPlanChange(type, period))) {
      return
    }

    setLoading(true)

    try {
      await billingService.changePlan(companyId, type, period, paymentInNewTab)

      if(paymentInNewTab) {
        selectPlan.close()
        setLoading(false)
      }
    } catch(e) {
      toastWarning(t('errors:insufficient_request'))
      setLoading(false)
    }
  }, [paymentInNewTab])

  const changePlan = useCallback((period?: PaymentPeriods, newTab = false) => {
    period && setDefaultPeriod(period)

    setPaymentInNewTab(newTab)
    selectPlan.show()
  }, [])

  return (
    <ChangeTariffProcessContext.Provider value={{ changePlan }}>
      {children}

      <SelectPremiumPlan
        {...selectPlan.state}
        defaultPeriod={defaultPeriod}
        onConnect={handlePlanSelected}
      />

      <CreateCompanyShort
        {...createCompany.state}
        onSuccess={onCompanyCreated}
      />

      <LoadingOverlay
        open={loading}
        fixed
      />
    </ChangeTariffProcessContext.Provider>

  )
})
