import Cargo2 from '@roolz/icons/lottie/Cargo2'
import Kran from '@roolz/icons/lottie/Kran'
import Moped from '@roolz/icons/lottie/Moped'
import Samolet from '@roolz/icons/lottie/Samolet'
import Zavod from '@roolz/icons/lottie/Zavod'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { ReactNode, useMemo } from 'react'
import { usePricingCurrency } from '@roolz/sdk/components/pricing/hooks/usePricingCurrency'
import { usePlanPricing } from '@roolz/sdk/components/pricing/hooks/useUserPricing'
import { getPlanVariant, getPriceType } from '@roolz/sdk/components/pricing/utils/plans'

const tariffIcons: Record<TariffPlanType, ReactNode> = {
  [TariffPlanType.Free]: <Moped/>,
  [TariffPlanType.Beginner]: <Kran/>,
  [TariffPlanType.Pro]: <Cargo2/>,
  [TariffPlanType.Expert]: <Samolet/>,
  [TariffPlanType.Enterprise]: <Zavod/>,
}

interface Options {
  paymentPeriod: PaymentPeriods
}

export const useBillingPlanView = (plan: TariffPlan, {
  paymentPeriod,
}: Options) => {
  const type = plan.type

  const pricing = usePlanPricing(plan)

  const price = useMemo((): string => {
    if(paymentPeriod === PaymentPeriods.Annual) {
      const price = Number.parseFloat(pricing.price_annual)

      return (price / 12).toFixed(2)
    }

    return pricing.price_monthly
  }, [paymentPeriod, pricing])

  const priceInteger = price.replace(/\..*/, '')
  const priceFractional = price.includes('.') ? price.replace(/.*\./, '') : ''

  const currencySymbol = usePricingCurrency({
    pricing,
  })

  return {
    Icon: tariffIcons[type],

    planVariant: getPlanVariant(type),
    priceType: getPriceType(type),
    price,

    priceInteger,
    priceFractional,
    currencySymbol,
  }
}
