import { Intro } from '@roolz/sdk/components/pricing/blocks/Intro/Intro'
import { Plans } from '@roolz/sdk/components/pricing/blocks/Plans/Plans';
import { Scope } from '@roolz/sdk/components/pricing/blocks/Scope/Scope'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import FAQ from '@roolz/sdk/components/ui/FAQ/FAQ'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { billingStore } from '@/store/billing/billing.store';
import { Modal, ModalProps } from '@/components/hoc/Modal';
import styles from './SelectPremiumPlan.module.scss';

type Props = {
  defaultPeriod?: PaymentPeriods
  onConnect?: (tariff: TariffPlan, period: PaymentPeriods) => Promise<void>
} & ModalProps

export const SelectPremiumPlan = Modal<Props>(observer(({
  defaultPeriod,
  onConnect,
  setOpen,
}: Props) => {
  const tariffs = billingStore.activePlans.filter(item => item.type !== 'free')
  const currentPlanType = billingStore.billing?.tariff_plan?.type ?? TariffPlanType.Free
  const currentPaymentPeriod = billingStore.billing?.billing_period

  useTranslation('pricing')

  const initialPeriod = useMemo(() => {
    if(defaultPeriod && Object.values(PaymentPeriods).includes(defaultPeriod)) {
      return defaultPeriod
    }

    return currentPlanType !== TariffPlanType.Free
      ? currentPaymentPeriod
      : PaymentPeriods.Annual
  }, [defaultPeriod, currentPlanType, currentPaymentPeriod])

  return (
    <div className={styles.root}>
      <CloseButton
        className={styles.close}
        onClick={() => setOpen(false)}
      />

      <div className={styles.container}>
        <Intro/>

        <Plans
          defaultPaymentPeriod={initialPeriod}
          currentPaymentPeriod={currentPaymentPeriod}
          currentPlanType={currentPlanType}
          minWidthForHorizontal={940}
          itemsClassName={styles.items}
          tariffs={tariffs}
          onConnect={onConnect}
        />
      </div>

      <Scope
        tariffs={tariffs}
        onConnect={onConnect}
      />

      <FAQ
        // titleKey='pricing:faq.title'
        //
        theme='light'
        animate={false}
        i18n_namespace='pricing'
        i18n_prefix='faq'
      />
    </div>
  )
}), {
  fullWidth: true,
  maxWidth: false,
  classes: {
    root: styles.dialog,
    paper: styles.paper,
  },
  // className: styles.root
})
