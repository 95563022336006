import { TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { findIndex } from 'lodash-es'

type PlanVariant = 'most_popular' | 'most_valuable'

const priceTypes: Record<TariffPlanType, string> = {
  [TariffPlanType.Free]: 'forever',
  [TariffPlanType.Beginner]: 'per_user',
  [TariffPlanType.Pro]: 'per_user',
  [TariffPlanType.Expert]: 'per_user',
  [TariffPlanType.Enterprise]: 'per_company',
}

const planVariants: Partial<Record<TariffPlanType, PlanVariant>> = {
  [TariffPlanType.Pro]: 'most_popular',
  [TariffPlanType.Expert]: 'most_valuable',
}

const texts = {
  [TariffPlanType.Free]: 'register',
  [TariffPlanType.Beginner]: 'connect',
  [TariffPlanType.Pro]: 'connect',
  [TariffPlanType.Expert]: 'connect',
  [TariffPlanType.Enterprise]: 'get_info',
}

export const getPlanVariant = (planType: TariffPlanType) => planVariants?.[planType]
export const getPriceType = (planType: TariffPlanType) => priceTypes?.[planType]
export const getPlanActionText = (planType: TariffPlanType) => `pricing:plan_actions.${texts[planType]}`

export const sortPlansForMobile = (tariffs: TariffPlan[]) => {
  const proIndex = findIndex(tariffs, { type: TariffPlanType.Pro })

  if (proIndex !== -1) {
    const res = [...tariffs]

    return [
      ...res.splice(proIndex, 1),
      ...res,
    ]
  }

  return tariffs
}
