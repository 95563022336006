import { DialogProps as MuiDialogProps } from '@mui/material/Dialog/Dialog'
import { ComponentType, useEffect, useState } from 'react'
import { Dialog } from '@/components/ui/Dialog/Dialog'

export interface ModalProps {
  open: boolean
  setOpen: (val: boolean) => void
}

export const Modal = function <T extends ModalProps>(Component: ComponentType<T & { onClose?: () => void }>, dialogProps?: Partial<MuiDialogProps>) {
  return (props: T) => {
    const {
      open,
      setOpen,
      ...rest
    } = props

    const [mount, setMount] = useState(false)

    useEffect(() => {
      if(open || dialogProps?.keepMounted) {
        setMount(true)

        return
      }

      setTimeout(() => void setMount(false), 300)
    }, [open])

    return (
      <Dialog
        maxWidth='xs'
        scroll='body'
        {...dialogProps}

        open={open}
        onClose={() => setOpen?.(false)}
      >
        {mount && (
          <Component
            {...props}
          />
        )}
      </Dialog>
    )
  }
}
