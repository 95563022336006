import { STATUSES } from '@/components/company-admin/forms/InviteMembersForm/InviteMembersForm'
import { Close } from '@roolz/icons/Close'
import { Mail } from '@roolz/icons/Mail'
import { Autocomplete, AutocompleteProps, InputAdornment, TextField } from '@mui/material'
import cn from 'classnames'
import React, {
  SyntheticEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import { useTranslation } from 'react-i18next'
import styles from './EmailMultiSelect.module.scss'

export type EmailItem = {
  value: string
  error: string | null
  variant?: 'error' | 'warning'
}

const SEPARATORS = [' ', ',', '|']

const TagEmail = ({
  item,
  onDelete,
}: {
  item: EmailItem
  onDelete: () => void
}) => {

  if (!item) {
    return null
  }

  return (
    <div className={cn(styles.tagEmail, styles[item.variant ?? ''])}>
      <p className={styles.tagEmail__text}>
        { item.value }
      </p>
      <div
        className={styles.tagEmail__icon}
        onClick={onDelete}
      >
        <Close
          color='#8E8E93'
          size={9}
        />
      </div>
    </div>
  )
}
type EmailMultiSelectProps = {
  onChange: (value: string[]) => void
  validate: (email: string) => Promise<EmailItem | null>
}
export const EmailMultiSelect = forwardRef(({
  onChange,
  validate
}: EmailMultiSelectProps, ref) => {
  const { t } = useTranslation('ui')
  const [value, setValue] = useState<string>('')
  const [emails, setEmails] = useState<EmailItem[]>([])
  const [items, setItems] = useState<string[]>([])

  useImperativeHandle(ref, () => {
    return {
      parseAndAddEmails
    }
  })

  const pushItems = (values: EmailItem[]) => {
    const newEmails = [...emails, ...values]
    setEmails(newEmails)
    onChange(newEmails.filter(email => !email.error).map(email => email.value))
  }

  const onDelete = (value: string) => {
    setItems(items.filter(item => value !== item))

    const newEmails = emails.filter(email => value !== email.value)

    setEmails(newEmails)
    onChange(newEmails.map(item => item.value))
  }

  const resetState = () => {
    setItems([])
    setEmails([])
    setValue('')
  }

  const handleInputChange = (_: SyntheticEvent, v: string) => {
    setValue(v)
  }

  const parseAndAddEmails = async () => {
    // we want to split the string by separating characters. useful when paste multiple emails
    const itemsStr = value.replaceAll(/(\||,)/g, ' ')

    if (!itemsStr?.trim()) return

    const newItems = Array.from(new Set(itemsStr.split(' ')))
      .filter((item: string) => item && !items.includes(item))

    const promises = newItems.map(validate)
    const emails = await Promise.all(promises)
    const isValid = emails.filter((email) => !email?.error).length

    setItems([...items, ...newItems])
    //@ts-ignore
    pushItems(emails.filter(Boolean))
    setValue('')

    return isValid
  }

  const handleChange = (event: any, value: any, reason: string) => {
    if (reason === "clear") {
      resetState()
      onChange([])
    }
  }

  const renderTags = () => emails.map((item) => (
    <TagEmail
      key={item.value}
      item={item}
      onDelete={() => onDelete(item.value)}
    />
  ))

  const isHaveLimitError = useMemo(() => !!emails.find(email => email.error === STATUSES.LIMIT), [emails])
  const isHaveDuplicateError = useMemo(() => !!emails.find(email => email.error === STATUSES.DUPLICATE), [emails])
  const isHaveIncorrectError = useMemo(() => !!emails.find(email => email.error === STATUSES.INCORRECT), [emails])
  const isHaveDeleteError = useMemo(() => !!emails.find(email => email.error === STATUSES.DELETE), [emails])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      parseAndAddEmails()
    }
  }

  useEffect(() => {
    if (SEPARATORS.includes(value.at(-1) || '')) {
      parseAndAddEmails()
    }
  }, [value])

  return (
    <Autocomplete
      multiple
      freeSolo
      inputValue={value}
      onInputChange={handleInputChange}
      options={[]}
      value={items}
      onChange={handleChange}
      renderTags={renderTags}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            variant="outlined"
            label=""
            placeholder={t('email_multi_select.placeholder')}
            onBlur={() => {
              parseAndAddEmails()
            }}
            className={styles.input}
            inputProps={{
              onKeyDown: handleKeyDown,
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position='start' className={styles.input__mail}>
                    <Mail color='#8E8E93' size={20}/>
                  </InputAdornment>
                  { params.InputProps.startAdornment }
                </>
              )
            }}
          />
          {isHaveDuplicateError &&
            <p className={styles.input__warning}>
              {t('email_multi_select.errors.duplicate')}
            </p>
          }
          {isHaveIncorrectError &&
            <p className={styles.input__error}>
              {t('email_multi_select.errors.incorrect')}
            </p>
          }
          {isHaveDeleteError &&
            <p className={styles.input__error}>
              {t('email_multi_select.errors.delete')}
            </p>
          }
          {isHaveLimitError &&
            <p className={styles.input__error}>
              {t('email_multi_select.errors.limit')}
            </p>
          }
        </>
      )}
    />
  )
})
