import CheckCircle from '@material-symbols/svg-400/rounded/check_circle.svg'
import Info from '@material-symbols/svg-400/rounded/info.svg'
import PlayArrowFill from '@material-symbols/svg-400/rounded/play_arrow-fill.svg'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { ReactNode, useCallback, useMemo } from 'react'
import { usePlanPricing } from '@roolz/sdk/components/pricing/hooks/useUserPricing'
import { PlanActionButton } from '@roolz/sdk/components/pricing/ui/PlanActionButton/PlanActionButton'
import {
  MonthlyPriceForYearPanel,
} from '@roolz/sdk/components/pricing/ui/MonthlyPriceForYearPanel/MonthlyPriceForYearPanel'
import { UCFirst } from '@roolz/sdk/utils/formatting'
import { useBillingPlanView } from '@roolz/sdk/components/pricing/hooks/useBillingPlanView'
import { MostPopularLabel } from '@roolz/sdk/components/pricing/ui/MostPopularLabel/MostPopularLabel'
import { MostValuableLabel } from '@roolz/sdk/components/pricing/ui/MostValuableLabel/MostValuableLabel'
import { useSdkContext, useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './Plan.module.scss'

export type PlanMode = 'vertical' | 'horizontal'

interface Props {
  isCurrent?: boolean
  plan: TariffPlan
  paymentPeriod: PaymentPeriods

  mode?: PlanMode

  name: ReactNode
  onConnect?: () => void
}

const transComponents = {
  b: <b/>,
  highlight: <span style={{ color: '#4778EE' }}/>,
  triangle: <MaterialSymbolIcon
    size={12}
    icon={<PlayArrowFill/>}
    style={{
      transform: 'rotate(-90deg) scale(2) translate(-10%, 0)',
      color: '#4778EE',
    }}
  />,
  // @ts-ignore
  grey: <grey/>,
}

export const Plan = observer(({
  mode = 'vertical',
  isCurrent,

  plan,
  paymentPeriod,

  name,
  onConnect,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  const { Trans } = useSdkContext()

  const { type } = plan

  const {
    Icon,
    planVariant,
    priceType,

    priceInteger,
    priceFractional,
    currencySymbol,
  } = useBillingPlanView(plan, {
    paymentPeriod,
  })

  const { price_credit } = usePlanPricing(plan)

  const handleConnect = useCallback(() => {
    if(type === 'enterprise') {
      return window.open('mailto:hey@roolz.net', '_blank')
    }

    onConnect?.()
  }, [type, onConnect])

  const middleButton = (
    <PlanActionButton
      isCurrent={isCurrent}
      planType={plan.type}
      onClick={handleConnect}
      className={cn(styles.button, styles.middleButton)}
    />
  )

  const descriptionBlocks = []
  if(type === 'free') {
    descriptionBlocks.push({ name: 'public_exchange' })
  }

  descriptionBlocks.push({ name: 'private_exchange' })
  descriptionBlocks.push({ name: 'common' })

  const getDescriptionBlockLength = (name: string) => t(`plans.items.${type}.description.${name}`, {
    returnObjects: true,
  })?.length

  const extraPrivateOfferPrice = currencySymbol + (Number(plan.scope.offer_private_crprice) * Number(price_credit))

  return (
    <div
      className={cn(styles.root, {
        [styles.rootPopular]: planVariant === 'most_popular',
        [styles.rootHorizontal]: mode === 'horizontal',
      })}
    >
      {planVariant === 'most_popular' && <MostPopularLabel className={styles.modifierLabel}/>}
      {planVariant === 'most_valuable' && <MostValuableLabel className={styles.modifierLabel}/>}

      <div className={styles.header}>
        <div className={styles.title}>
          <h2 className={styles.name}>
            {name}
          </h2>

          {Icon && (
            <div className={styles.icon}>
              {Icon}
            </div>
          )}
        </div>

        <div className={styles.price}>
          {type === TariffPlanType.Enterprise ? (
            <span className={styles.priceIndividual}>
              {UCFirst(t('plans.price_types.individual'))}
            </span>
          ) : (
            <>
              <span className={styles.price__currency}>
                {currencySymbol}
              </span>

              <span className={styles.price__quantity}>
                {priceInteger}

                {Number.parseInt(priceFractional, 10) > 0 && (
                  <>
                    .
                    <span className={styles.price__quantityFractional}>
                      {priceFractional}
                    </span>
                  </>
                )}
              </span>
              {priceType && (
                <span className={styles.price__period}>
                  /
                  {t(`plans.price_types.${priceType}`)}
                </span>
              )}

              {paymentPeriod === PaymentPeriods.Annual && (
                <MonthlyPriceForYearPanel
                  visible={type !== TariffPlanType.Free}
                />
              )}
            </>
          )}
        </div>

        <div className={styles.header__description}>
          {t(`plans.items.${type}.under_price_description`)}
        </div>

        {mode === 'horizontal' && middleButton}
      </div>

      <div className={styles.description}>
        {mode === 'vertical' && middleButton}

        {type !== 'free' && (
          <div className={styles.description__prevPlan}>
            <Trans
              t={t}
              i18nKey={`pricing:plans.items.${type}.description.prev_plan_features`}
              components={transComponents}
            />
          </div>
        )}

        <div className={styles.description__blocks}>
          {descriptionBlocks.map(({ name }) => (
            <div className={styles.description__block}>
              <div className={styles.description__block__name}>
                {t(`plans.description_block_names.${name}`)}
              </div>
              <ul className={styles.list}>
                {new Array(getDescriptionBlockLength(name)).fill(0)
                  .map((item, i) => (
                    <li>
                      <MaterialSymbolIcon
                        className={styles.list__icon}
                        size={16}
                        icon={<CheckCircle/>}
                      />

                      <span>
                        <Trans
                          t={t}
                          i18nKey={`pricing:plans.items.${type}.description.${name}.${i}`}
                          components={transComponents}
                          values={{
                            privateOffersCount: plan.scope.offers_private_publish,
                            extraPrivateOfferPriceCr: plan.scope.offer_private_crprice,
                            extraPrivateOfferPrice,
                            publicOffersViewsCount: plan.scope.offers_views,
                          }}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {mode === 'vertical' && (
        <PlanActionButton
          isCurrent={isCurrent}
          planType={plan.type}
          isSecondary
          onClick={handleConnect}
          className={cn(styles.button, styles.bottomButton)}
        />
      )}
    </div>
  )
})
