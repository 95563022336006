import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import AccountBalanceWallet from '@material-symbols/svg-400/rounded/account_balance_wallet.svg'
import { CheckCircleFill } from '@roolz/icons/CheckCircleFill'
import { Close } from '@roolz/icons/Close'
import { CompanyAndOrganization } from '@roolz/icons/companies/CompanyAndOrganization'
import { CompanyProfile } from '@roolz/icons/companies/CompanyProfile'
import { GroupsAndDepartments } from '@roolz/icons/companies/GroupsAndDepartments'
import { UsersIcon } from '@roolz/icons/companies/Users'
import { HourglassEmpty } from '@roolz/icons/HourglassEmpty'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import { Menu } from '@roolz/icons/Menu'
import { RunningWithErrors } from '@roolz/icons/RunningWithErrors'
import { ArrowRightAlt } from '@roolz/icons/sidebar/ArrowRightAlt'
import { toastError } from '@roolz/sdk/components/snackbars'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Company, CompanyRoles } from '@roolz/types/api/companies'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { companyStore, EditFormType } from '@/store/companies/company.store'
import { companiesService } from '@/store/companies/companies.service'
import { companyMembersStore } from '@/store/companyMembers/companyMembers.store'
import { companiesStore } from '@/store/companies/companies.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { companyMembersService } from '@/store/companyMembers/companyMembers.service'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { chatsService } from '@/store/chats/chats.service'
import { useBackToAppFromCompanyAdmin } from '@/pages/home/companies/Admin/utils'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { Docs } from '@/components/company-admin/ui/Docs/Docs';
import { CompanyEditForm } from '@/components/company-admin/forms/CompanyEditForm/CompanyEditForm'
import { EditModalDirections } from '@/components/companies/Cards/EditModalDirections'
import styles from './CompanyLayout.module.scss'

type LogoProps = {
  name?: string
  color?: string
  logoUrl?: string | null
}

const MobileLogo = ({
  name,
  color,
  logoUrl,
}: LogoProps) => (
  <Avatar
    avatarUrl={logoUrl ?? ''}
    first_name={name}
    color_code={color}
    width={32}
    type='company'
  />
)

const Logo = ({
  name,
  color,
  logoUrl,
}: LogoProps) => (
  <div className={styles.sidebar__company}>
    <Avatar
      className={styles.sidebar__companyAvatar}
      avatarUrl={logoUrl ?? ''}
      first_name={name}
      color_code={color}
      width={48}
      type='company'
    />
    <p className={styles.sidebar__logoText}>
      {name}
    </p>
  </div>
)

type HeaderProps = {
  menuClick: () => void
  isShowSidebarMobile: boolean
  company: Company
}

const Header = ({
  menuClick,
  isShowSidebarMobile,
  company,
}: HeaderProps) => (
  <header className={styles.header}>
    <div className={styles.header__menu} onClick={menuClick}>
      {isShowSidebarMobile ? <Close/> : <Menu/>}
    </div>
    <MobileLogo name={company.name} color={company.color} logoUrl={company.logo_path}/>
  </header>
)

type SidebarProps = {
  isShowMobile: boolean
  setIsShowSidebar: (value: boolean) => void
  company: Company
}

const Sidebar = observer(({
  isShowMobile,
  setIsShowSidebar,
  company,
}: SidebarProps) => {
  const { t } = useTranslation('company/admin')

  const companyId = company.id
  const pending = companyMembersStore.statuses.with_status_pending.total
  const wait = companyMembersStore.statuses.with_status_await.total
  const errors = companyMembersStore.statuses.with_status_email_errors.total
  const isShowStatuses = pending + wait + errors > 0

  const back = useBackToAppFromCompanyAdmin()

  return (
    <div
      className={cn(styles.sidebar, {
        [styles.sidebar_showMobile]: isShowMobile,
      })}
    >
      <button
        className={styles.sidebar__back}
        onClick={back}
      >
        <div className={styles.sidebar__backIcon}>
          <ArrowRightAlt/>
        </div>
        <span>
          {t('layout.back')}
        </span>
      </button>
      {!isShowMobile
        && <Logo name={company.name} color={company.color} logoUrl={company.logo_path}/>}
      <div className={styles.sidebar__list}>
        <SidebarMenuItem
          label={t('layout.sidebar.company_and_organization')}
          prepend={<CompanyAndOrganization/>}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_ADMIN_MAIN).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        />
        <SidebarMenuItem
          label={t('layout.sidebar.company_profile')}
          prepend={<CompanyProfile/>}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_ADMIN_PROFILE).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        />
        <SidebarMenuItem
          label={t('layout.sidebar.company_users')}
          prepend={<UsersIcon/>}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_ADMIN_USERS).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        >
          {isShowStatuses
            && (
              <div className={styles.menuItem__statuses}>
                {!!pending
                && (
                  <div className={styles.menuItem__status}>
                    <CheckCircleFill/>
                    <span className={styles.menuItem__statusPending}>
                      { pending }
                    </span>
                  </div>
                )}
                {!!wait
                && (
                  <div className={styles.menuItem__status}>
                    <HourglassEmpty/>
                    <span className={styles.menuItem__statusAwait}>
                      { wait }
                    </span>
                  </div>
                )}
                {!!errors
                && (
                  <div className={styles.menuItem__status}>
                    <RunningWithErrors/>
                    <span className={styles.menuItem__statusError}>
                      { errors }
                    </span>
                  </div>
                )}
              </div>
            )}
        </SidebarMenuItem>
        <SidebarMenuItem
          label={t('layout.sidebar.groups_and_departments')}
          prepend={<GroupsAndDepartments color='#c8cbde'/>}
          to=''
          disabled
        />
        <SidebarMenuItem
          label={t('layout.sidebar.pricing')}
          prepend={(
            <MaterialSymbolIcon
              icon={<AccountBalanceWallet/>}
            />
          )}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_ADMIN_PRICING).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        />
      </div>

      <Docs className={styles.sidebar__docs}/>
    </div>
  )
})

interface SidebarMenuItemProps {
  label: string
  prepend?: ReactNode
  to: string,
  disabled?: boolean
  children?: ReactNode

  [key: string]: any
}

export const SidebarMenuItem = ({
  label,
  prepend,
  to,
  disabled = false,
  children,
  ...rest
}: SidebarMenuItemProps) => {
  const { pathname } = useLocation()

  const isActive = useMemo(
    () => !disabled && new RegExp(`^${to}(/.*)?`).test(pathname),
    [pathname],
  )

  if (disabled) {
    return (
      <div
        {...rest}
        className={cn(styles.menuItem, {
          [styles.menuItemDisabled]: disabled,
          [styles.menuItemActive]: isActive,
        })}
      >
        <div className={styles.menuItem__text}>
          <div className={styles.menuItem__icon}>
            {prepend}
          </div>
          <span className={styles.menuItem__label}>
            {label}
          </span>
        </div>
        { isActive && children }
      </div>
    )
  }

  return (
    <Link
      to={to}
      {...rest}
      className={cn(styles.menuItem, {
        [styles.menuItemDisabled]: disabled,
        [styles.menuItemActive]: isActive,
      })}
    >
      <div className={styles.menuItem__text}>
        <div className={styles.menuItem__icon}>
          {prepend}
        </div>

        <span className={styles.menuItem__label}>
          {label}
        </span>
      </div>
      { isActive && children }
    </Link>
  )
}

interface Props {
  children: ReactNode
}

const CompanyLayout = observer(({
  children,
}: Props) => {
  const { pathname } = useLocation()
  const params = useParams()
  const companyId = params?.company_id
  const [isShowSidebarMobile, setIsShowSidebarMobile] = useState(false)
  const { t: errorsT } = useTranslation('errors')
  const navigate = useNavigate()
  const activeEditForm = companyStore.activeEditForm
  const company = companiesStore.myCompany

  if (!companyId) {
    return null
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    companyMembersService.loadStatuses(companyId)
    chatsService.setActiveChat(null)
  }, [])

  const checkAccess = () => {
    const myRole = (myCompaniesStore.companies || []).find(({ id }) => id === companyId)?.my_role
    const isAccessAllowed = myRole && [CompanyRoles.owner, CompanyRoles.admin].includes(myRole)

    if (!isAccessAllowed) {
      navigate('/', { replace: true })
    }

    return isAccessAllowed
  }

  const loadMyCompany = async () => {
    try {
      await companiesService.getMyCompanyById({
        id: companyId,
      })
    } catch(e: any) {
      toastError(e?.response?.error_msg ?? errorsT('insufficient_request'))
      if (e?.response?.error_msg === 'Company not exists.') {
        await Promise.all([
          companiesService.loadMyCompanies(),
          profilesService.retrieveMyProfile(),
        ])
        navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
      }
    }
  }

  useEffect(() => () => {
    companiesStore.myCompany = null
    companiesStore.isMyCompanyLoading = false
  }, [])

  useEffect(() => {
    const access = checkAccess()
    if (!access) {
      return
    }

    if (!company) {
      loadMyCompany()
    }
  }, [companyId, company])

  useEffect(() => {
    companyStore.activeEditForm = null
  }, [pathname])

  if (!company) {
    return <Loadable className={styles.loader} loading/>
  }

  const renderEditForm = () => {
    switch(activeEditForm) {
      case EditFormType.DIRECTIONS: return (
        <EditModalDirections
          company={company}
        />
      )
      case EditFormType.COMPANY_EDIT: return (
        <CompanyEditForm
          company={company}
        />
      )
    }
  }

  return (
    <div className={styles.root}>
      {/* <InviteMembersAppeal open={isShowModal} setOpen={handleCloseModal} /> */}
      <Header
        menuClick={() => setIsShowSidebarMobile(!isShowSidebarMobile)}
        isShowSidebarMobile={isShowSidebarMobile}
        company={company}
      />
      <div className={styles.company}>
        <Sidebar
          isShowMobile={isShowSidebarMobile}
          setIsShowSidebar={setIsShowSidebarMobile}
          company={company}
        />
        {!activeEditForm
          ? <>{children}</>
          : (
            <div className={styles.company__dialog}>
              <main className={styles.company__main}>
                {renderEditForm()}
              </main>
            </div>
          )}
      </div>
    </div>
  )
})

export default CompanyLayout
