import { useSdkContext } from '@roolz/sdk/SdkContext'
import { TariffPrice } from '@roolz/types/api/billing'
import { Currency } from '@roolz/types/api/knowledge/exchange'
import { useMemo } from 'react'

interface Props {
  pricing: TariffPrice
}

export const usePricingCurrency = ({
  pricing
}: Props) => {
  const { knowledge: { supportedCurrencies } } = useSdkContext()

  const findCurrencySymbol = (slug: string) => supportedCurrencies
    .find(item => item.slug.toLowerCase() === slug.toLowerCase())?.symbol ?? slug

  return useMemo(() => findCurrencySymbol(pricing.currency), [
    pricing.currency, supportedCurrencies
  ])
}
