import { TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import cn from 'classnames'
import React, { memo } from 'react'
import { TablePlanHead } from '@roolz/sdk/components/pricing/blocks/PlansScope/desktop/TablePlanHead/TablePlanHead'
import styles from '@roolz/sdk/components/pricing/blocks/PlansScope/PlansScope.module.scss'
import { MostPopularLabel } from '@roolz/sdk/components/pricing/ui/MostPopularLabel/MostPopularLabel'
import { MostValuableLabel } from '@roolz/sdk/components/pricing/ui/MostValuableLabel/MostValuableLabel'
import { PlanActionButton } from '@roolz/sdk/components/pricing/ui/PlanActionButton/PlanActionButton'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'

interface Props {
  tariffs: TariffPlan[]
  onConnect?: (plan: TariffPlan) => void
}

export const TableHeadDesktop = memo(({
  tariffs,
  onConnect,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <thead>
      <tr>
        <th
          className={styles.tdFill}
          style={{ borderBottom: 0 }}
        />

        {tariffs.map(tariff => (
          <th className={styles.planName}>
            {t(`plans.items.${tariff.type}.title`)}

            {tariff.type === TariffPlanType.Pro && (
              <MostPopularLabel className={styles.planName__label}/>
            )}
            {tariff.type === TariffPlanType.Expert && (
              <MostValuableLabel className={styles.planName__label}/>
            )}
          </th>
        ))}
      </tr>
      <tr>
        <th className={styles.tdFill} style={{ borderBottom: 0 }}/>

        {tariffs.map(tariff => (
          <TablePlanHead tariff={tariff}/>
        ))}
      </tr>

      <tr>
        <th className={cn(styles.tdFill, styles.topActionCell)}/>

        {tariffs.map(tariff => (
          <th className={styles.topActionCell}>
            <PlanActionButton
              onClick={() => onConnect?.(tariff)}
              className={styles.topAction}
              planType={tariff.type}
            />
          </th>
        ))}
      </tr>
    </thead>
  )
})
