import { Slide } from '@mui/material'
import KPP, { GREEN_SIGNAL_END_FRAME, RED_SIGNAL_END_FRAME, YELLOW_SIGNAL_END_FRAME } from '@roolz/icons/lottie/KPP'
import { Loadable } from '@roolz/sdk/components'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { useHover } from '@roolz/sdk/hooks/useHover'
import { useLottieControls } from '@roolz/sdk/hooks/useLottieControls'
import { BillingPermissionName, TariffPlanType } from '@roolz/types/api/billing'
import { CompanyRoles } from '@roolz/types/api/companies'
import { useUpdateEffect } from 'ahooks'
import { LottieRefCurrentProps } from 'lottie-react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { profilesStore } from '@/store/profiles/profiles.store'
import { companiesStore } from '@/store/companies/companies.store'
import { billingStore } from '@/store/billing/billing.store'
import { Modal } from '@/components/hoc/Modal'
import styles from './BillingLimitReached.module.scss'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any
  permissionName?: BillingPermissionName
  onTopUp?: () => void
  onChangePlan?: () => void
}

const Content = observer(({
  open,
  setOpen,
  permissionName,
  onTopUp,
  onChangePlan,
}: Props) => {
  const { t } = useTranslation('modals')

  const { tariff_plan } = billingStore.billing

  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const { playTo } = useLottieControls(lottieRef)

  const [isTopUpClicked, setIsTopUpClicked] = useState(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)

  const isLoading = isTopUpClicked || isButtonClicked

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const isButtonHover = useHover(buttonRef)

  useEffect(() => {
    setTimeout(() => {
      lottieRef.current?.setSpeed(1.5)
      playTo(RED_SIGNAL_END_FRAME)
    }, 100)
  }, [])

  useUpdateEffect(() => {
    if(!isButtonClicked) {
      playTo(
        isButtonHover ? YELLOW_SIGNAL_END_FRAME : RED_SIGNAL_END_FRAME,
      )
      lottieRef.current?.setSpeed(2)
    }
  }, [isButtonHover])

  const canAdministrate = () => !companiesStore.activeCompany?.my_role
    || [
      CompanyRoles.admin,
      CompanyRoles.owner,
    ].includes(companiesStore.activeCompany?.my_role)

  const canTopUp = !!profilesStore.activeCompanyId
    && permissionName
    && [BillingPermissionName.PublishPrivateOffer].includes(permissionName)

  const playFullAnimation = async () => new Promise(resolve => {
    playTo(GREEN_SIGNAL_END_FRAME)
    lottieRef.current?.setSpeed(2)

    lottieRef.current?.animationItem?.addEventListener('complete', resolve)
  })

  const handleTopUp = async () => {
    setIsTopUpClicked(true)
    await playFullAnimation()

    setOpen(false)
    onTopUp?.()
  }

  const handleConfirm = async () => {
    if(canAdministrate() && tariff_plan?.type !== TariffPlanType.Enterprise) {
      setIsButtonClicked(true)
      await playFullAnimation()

      onChangePlan?.()
    }
    setOpen(false)
  }

  return (
    <div className={styles.root}>
      <Slide direction='up' in appear>
        <div>
          <KPP
            className={styles.kpp}
            lottieRef={lottieRef}
            loop={false}
          />
        </div>
      </Slide>

      <div className={styles.content}>
        <div className={styles.title}>
          {t('billing_limit_reached.title')}
        </div>

        <div className={styles.description}>
          {t(`billing_limit_reached.scope_description.${permissionName}`)}
        </div>

        {canAdministrate() ? (
          <>
            {canTopUp && (
              <Loadable loading={isTopUpClicked}>
                <Button
                  disabled={isLoading}
                  className={styles.topUpButton}
                  variant='text'
                  onClick={handleTopUp}
                >
                  {t('billing_limit_reached.top_up_button')}
                </Button>
              </Loadable>
            )}

            <div
              className={styles.appeal}
              style={{
                marginTop: !canTopUp ? 24 : 0,
              }}
            >
              {t('billing_limit_reached.change_plan_appeal')}
            </div>
          </>

        ) : (
          <div
            className={styles.appeal}
            style={{
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            {t('billing_limit_reached.contact_company_admin_appeal')}
          </div>
        )}
      </div>

      <Loadable loading={isButtonClicked}>
        <Button
          color={canAdministrate() ? 'green' : 'primary'}
          className={styles.accept}
          onClick={handleConfirm}
          ref={buttonRef}
          disabled={isLoading}
        >
          {canAdministrate() && tariff_plan?.type !== TariffPlanType.Enterprise
            ? t('billing_limit_reached.change_tariff_button')
            : t('billing_limit_reached.close_button')}
        </Button>
      </Loadable>

      {/* <button */}
      {/*  className={cn(styles.accept, { */}
      {/*    [styles.acceptBlue]: !canTopUp */}
      {/*  })} */}
      {/*  onClick={() => setOpen(false)} */}
      {/* > */}
      {/*  {canTopUp */}
      {/*    ? t('private_offers_limit_reached.admin_close_button') */}
      {/*    : t('private_offers_limit_reached.close_button') */}
      {/*  } */}
      {/* </button> */}
    </div>
  )
})

export const BillingLimitReached = Modal(Content, {
  classes: {
    paper: styles.paper,
  },
})
