import { Billing, BillingPermissionName, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { find } from 'lodash-es'
import { makeAutoObservable } from 'mobx'

export class BillingStore {
  activePlans: TariffPlan[] = []
  billing: Billing

  // unknown error may appear if some of backend endpoints return 402 permission name, that isn't supported on front side
  billingError: BillingPermissionName | 'unknown' | null
  // lackingSeatsCount: null | number

  findPlan(type: TariffPlanType) {
    return find(this.activePlans, { type })
  }

  get nextTariffPlan(): TariffPlan | undefined {
    const currentPlanType = this.billing.tariff_plan.type

    const i = this.activePlans.findIndex(plan => plan.type === currentPlanType)

    return this.activePlans.at(i + 1)
  }

  updateBilling(billing: Billing) {
    this.billing = billing
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const billingStore = new BillingStore()
